import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import classNames from "classnames";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ paddingTop: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

const styles = theme => ({
  root: {
    flexGrow: 0,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  tabsRoot: {
    borderBottom: "1px solid #e8e8e8",
    backgroundColor: 'white',
    display: "flex",
    flexWrap: "wrap",
    flexGrow: 0,
    justifyContent: "space-between",
    width: "100%"
  },
  tabsIndicator: {
    backgroundColor: "#1890ff",
  },
  tabRoot: {
    ...theme.typography.paragraph,
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    textTransform: "initial",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "inherit",
    position: "fix",
    minWidth: "30%",
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
      fontFamily: "Lato",
      fontSize: "16px",
      fontWeight: "bold"
    },
    "&$tabSelected": {
      color: "#1890ff",
      fontFamily: "Lato",
      fontSize: "16px",
      fontWeight: "bold"
    },
    "&:focus": {
      color: "#40a9ff",
      fontFamily: "Lato",
      fontSize: "16px",
      fontWeight: "bold"
    }
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing.unit * 3
  },
  paragraph: {
    ...theme.typography.paragraph,
    lineHeight: "1.8",
    color: "#777777",
    fontSize: "16px",
    fontStyle: "normal",
    fontFamily: "Lato",
  },
  space: {
    marginTop: theme.spacing.unit * 4
  },
  link: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#4198ff',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  right: {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: "500",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "right",
    color: "#4198ff",
    backgroundColor: "white"
  }
});

class CustomizedTabs extends React.Component {
  state = {
    value: 0
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { data, classes } = this.props;
    const { value } = this.state;

    return (
      <div className={classes.root}>
        <Tabs
          value={value}
          onChange={this.handleChange}
          classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
        >
          {data.map((v, k) => (
            <Tab
              key={k}
              disableRipple
              classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
              label={v.heading}
            />
          ))}
        </Tabs>
        {value === 0 && (
          <TabContainer>
            <Grid direction="row" justify="space-between" container>
              <Grid item xs={6} md={6}>
                <div
                  className={classes.paragraph}
                  dangerouslySetInnerHTML={{ __html: data[value].description }}
                  style={{ lineHeight: "30px" }}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography
                  className={classNames(classes.link, classes.right)}
                  gutterBottom
                >
                  {data[value].phone}
                </Typography>
                <Typography
                  className={classNames(classes.link, classes.right)}
                  gutterBottom
                >
                  {data[value].email}
                </Typography>
              </Grid>
              &#160;
              <Grid item xs={12}>
                <iframe
                  width="100%"
                  height="298"
                  src="https://www.google.com/maps/embed?width=400&amp;height=298&amp;pb=!1m18!1m12!1m3!1d3022.38060854042!2d-73.9709274!3d40.7536528!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258e2ab8b5985%3A0xc00ac7d23adeb39e!2s225%20E%2047th%20St%2C%20New%20York%2C%20NY%2010017!5e0!3m2!1sen!2sus!4v1701728242490!5m2!1sen!2sus"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                />
              </Grid>
            </Grid>
          </TabContainer>
        )}
        {value === 1 && (
          <TabContainer>
            <Grid direction="row" justify="space-between" container>
              <Grid item xs={6} md={6}>
                <div
                  className={classes.paragraph}
                  dangerouslySetInnerHTML={{ __html: data[value].description }}
                  style={{ lineHeight: "30px" }}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography
                  className={classNames(classes.link, classes.right)}
                  gutterBottom
                >
                  {data[value].phone}
                </Typography>
                <Typography
                  className={classNames(classes.link, classes.right)}
                  gutterBottom
                >
                  {data[value].email}
                </Typography>
              </Grid>
              &#160;
              <Grid item xs={12}>
                <iframe
                  width="100%"
                  height="298"
                  src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;coord=28.48971,77.062282&amp;q=Plot%2023%2C%20Maruti%20Industrial%20Area%2C%20Sector%2018%2C%20Gurugram%2C%20Haryana%20122015%2C%20India+(Primoko%20Softwares%20Pvt%20Ltd)&amp;ie=UTF8&amp;t=&amp;z=15&amp;iwloc=B&amp;output=embed"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                />
              </Grid>
            </Grid>
          </TabContainer>
        )}
        {value === 2 && (
          <TabContainer>
            <Grid direction="row" justify="space-between" container>
              <Grid item xs={6} md={6}>
                <div
                  className={classes.paragraph}
                  dangerouslySetInnerHTML={{ __html: data[value].description }}
                  style={{ lineHeight: "30px" }}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography
                  className={classNames(classes.link, classes.right)}
                  gutterBottom
                >
                  {data[value].phone}
                </Typography>
                <Typography
                  className={classNames(classes.link, classes.right)}
                  gutterBottom
                >
                  {data[value].email}
                </Typography>
              </Grid>
              &#160;
              <Grid item xs={12}>
                <iframe
                  width="100%"
                  height="298"
                  src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;coord=46.747056,23.596989&amp;q=Marin%20Preda%20Street%20No.%2010-12%2C%20Cluj-Napoca%2C%20Romania%2C%20400502+(Primoko%20Softwares%20Pvt%20Ltd)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                />
              </Grid>
            </Grid>
          </TabContainer>
        )}
      </div>
    );
  }
}

CustomizedTabs.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CustomizedTabs);
