import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import CustomizedTabs from "../components/CustomizedTabs";
import LetsTalk from "../components/LetsTalk";
import Layout from '../components/layout';
import { graphql } from 'gatsby';

const styles = theme => ({
  container: {
    marginLeft: "0px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing.unit * 4,
      paddingRight: theme.spacing.unit * 4,
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing.unit * 22,
      paddingRight: theme.spacing.unit * 22,
      paddingTop: theme.spacing.unit * 5,
      paddingBottom: theme.spacing.unit * 5
    }
  },
  title: {
    ...theme.typography.subheading,
    color: "black",
    textTransform: "uppercase",
    fontSize: "40px",
    fontStyle: "normal",
    fontFamily: "Lato",
    lineHeight: "normal",
    fontWeight: 900,
    fontStretch: "normal",
    letterSpacing: "normal",
  },
  label: {
    ...theme.typography.paragraph,
    fontSize: "12px"
  },
  input: {
    width: "100%",
    height: "33px",
    borderRadius: "4px",
    backgroundColor: "#f8f8f8",
    border: "solid 1px #d1d1d1"
  },
  textfield: {
    width: "100%",
    height: "121px",
    borderRadius: "4px",
    backgroundColor: "#f8f8f8",
    border: "solid 1px #d1d1d1"
  },
  divider: {
    marginTop: "1.5%",
    marginBottom: "1.5%",
    backgroundColor: "#d1d1d1"
  },
  button: {
    ...theme.typography.paragraph,
    fontWeight: "bold",
    backgroundColor: "#4794ff",
    margin: "auto",
    padding: "15px",
    color: "white",
    textTransform: "uppercase",
    borderLeft: "4px solid #1e58a8",
    minWidth: "203px",
    boxSizing: "content-box",
    marginTop: theme.spacing.unit * 4,
  },
  gridContainer: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  }
});

class LetsTalkPage extends React.Component {

  render() {
    const { data, classes, location } = this.props;
    const { frontmatter } = data.markdownRemark;

    return (
      <Layout location={location}>
        <React.Fragment>
        <Grid
          container
          className={classNames(classes.container, classes.gridContainer)}
          justify="space-between"
          spacing={40}
        >
          <Grid item xs={12} md={6} lg={6}>
            <Typography className={classes.title} gutterBottom>
              Contact us
            </Typography>
            <LetsTalk />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography className={classes.title} gutterBottom>
              {frontmatter.title}
            </Typography>
            <CustomizedTabs data={frontmatter.office} />
          </Grid>
        </Grid>
      </React.Fragment>
      </Layout>
    );
  }
}

export default withStyles(styles)(LetsTalkPage);

export const pageQuery = graphql`
  query LetsTalkPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        office {
          heading
          description
          phone
          email
          map
        }
        testimonials {
          author
          quote
        }
      }
    }
  }
`;
