import React from "react";
import Divider from "@material-ui/core/Divider";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
  label: {
    ...theme.typography.paragraph,
    fontSize: "12px",
    color: "#777"
  },
  input: {
    width: "100%",
    height: "33px",
    borderRadius: "4px",
    backgroundColor: "#f8f8f8",
    border: "solid 1px #d1d1d1"
  },
  textfield: {
    width: "100%",
    height: "121px",
    borderRadius: "4px",
    backgroundColor: "#f8f8f8",
    border: "solid 1px #d1d1d1"
  },
  divider: {
    marginTop: "1.5%",
    marginBottom: "1.5%",
    backgroundColor: "#d1d1d1"
  },
  button: {
    ...theme.typography.paragraph,
    fontWeight: "bold",
    backgroundColor: "#4794ff",
    margin: "auto",
    padding: "15px",
    color: "white",
    textTransform: "uppercase",
    borderLeft: "4px solid #1e58a8",
    minWidth: "203px",
    boxSizing: "content-box",
    marginTop: theme.spacing.unit * 4
  }
});

class LetsTalk extends React.Component {
  constructor() {
    super();
    this.state = {
      name: ``,
      email: ``,
      phone: ``,
      subject: ``,
      message: ``,
      status: ``
    };
  }

  // Update state each time user edits their email address
  _handleEmailChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  _postEmailToMailchimp = (email, attributes) => {
    addToMailchimp(email, attributes)
      .then(result => {
        // Mailchimp always returns a 200 response
        // So we check the result for MC errors & failures
        if (result.result !== `success`) {
          this.setState({
            status: `error`,
            msg: result.msg
          });
        } else {
          // Email address succesfully subcribed to Mailchimp
          this.setState({
            status: `success`,
            msg: result.msg
          });
        }
      })
      .catch(err => {
        // Network failures, timeouts, etc
        this.setState({
          status: `error`,
          msg: err
        });
      });
  };

  _handleFormSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState(
      {
        status: `sending`,
        msg: null
      },
      // setState callback (subscribe email to MC)
      this._postEmailToMailchimp(this.state.email, {
        FNAME: this.state.name,
        EMAIL: this.state.email,
        PHONE: this.state.phone,
        SUBJECT: this.state.subject,
        MESSAGE: this.state.message
      })
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <form id="email-capture" method="post" noValidate>
        <Grid container justify="space-between" spacing={40}>
          <Grid item xs={12} md={6}>
            <label className={classes.label}>Name</label>
            <input
              onChange={this._handleEmailChange}
              className={classes.input}
              name="name"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <label className={classes.label}>Email address</label>
            <input
              onChange={this._handleEmailChange}
              className={classes.input}
              name="email"
            />
          </Grid>
        </Grid>
        <Grid container justify="space-between" spacing={40}>
          <Grid item xs={12} md={6}>
            <label className={classes.label}>Phone number (optional)</label>
            <input
              onChange={this._handleEmailChange}
              className={classes.input}
              name="phone"
            />
          </Grid>
        </Grid>

        <Grid container justify="space-between" spacing={40}>
          <Grid item xs={12} md={12}>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>

        <Grid container justify="space-between" spacing={40}>
          <Grid item xs={12} md={6}>
            <label className={classes.label}>Subject</label>
            <input
              onChange={this._handleEmailChange}
              className={classes.input}
              name="subject"
            />
          </Grid>
        </Grid>

        <Grid container justify="space-between" spacing={40}>
          <Grid item xs={12} md={12}>
            <label className={classes.label}>Message</label>
            <textarea
              onChange={this._handleEmailChange}
              className={classes.textfield}
              name="message"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <button className={classes.button} onClick={this._handleFormSubmit}>
            Send message
          </button>
        </Grid>
      </form>
    );
  }
}

export default withStyles(styles)(LetsTalk);
